import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ProjectPage from 'components/ProjectPage'

const Project = ({ data }) => (
  <>
    <Helmet title='Libra | François Deguire' />
    <ProjectPage
      name='Libra'
      description={`
        The goal of this five-week project was to create an object intended for
        sale at an exhibition. 12 copies were created and sold under 30$ each.
        Libra is a mobile work lamp that frees up the table surface by being
        affixed to the wall like a painting. Manufactured with thin ash wood slats
        and using LEDs, the lamp’s subdued aesthetic easily integrates into contemporary spaces.
      `}
      meta={`
        For Université de Montréal<br>
        In 2017
      `}
      images={Object.values(data).map(v => v.childImageSharp.fluid)}
    />
  </>
)

export const query = graphql`
  query {
    libra1: file(relativePath: { eq: "libra/1.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    libra2: file(relativePath: { eq: "libra/2.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    libra3: file(relativePath: { eq: "libra/3.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    libra4: file(relativePath: { eq: "libra/4.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    libra5: file(relativePath: { eq: "libra/5.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    libra6: file(relativePath: { eq: "libra/6.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Project
